import {
  Component,
  Input,
  HostBinding,
  EventEmitter,
  Output,
  OnChanges,
} from '@angular/core';
import { ButtonSize, ButtonVariant } from './button.types';
import { coerceBoolean } from 'coerce-property';

@Component({
  selector: 'zelis-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  @Input() variant: ButtonVariant = 'flat';
  @Input() size: ButtonSize = 'medium';
  @Input() color = 'primary';
  @Input() darkBackground = false;
  @Input() type = 'button';
  @Input() disabled = false;
  @Input() href = '';
  @Input() forwardedRouterLink = '';
  @Input() target = '_self';
  @Input() nowrap = true;
  /**
   * Whether the button truncates overflow text.
   * Responsive typography will automatically be applied when truncate is false.
   */
  @Input() truncate = false;
  @Input() @coerceBoolean fullWidth = false;
  /**
   * Whether to apply the alternative hover styling.
   * Currently only supported for stroke buttons.
   */
  @Input() @coerceBoolean alternateHover?: boolean;
  /**
   * Whether the button only contains an icon - no other content
   */
  @Input() @coerceBoolean iconButton?: boolean;
  /**
   * Whether the button is in a loading state - displaying a spinner
   */
  @Input() @coerceBoolean loading?: boolean;
  /**
   * CSS classes to apply to the button element.
   * Avoid use, as button types/variants should be defined.
   */
  @Input() forwardedClass?: string = '';
  @Input() ariaLabel?: string;

  @Output() clickFn = new EventEmitter<Event>();

  @HostBinding() class = 'zelis-dls';

  steps: string[] = [];

  ngOnChanges() {
    this.validateInputs();
    this.setSteps();
    this.setFullWidth();
  }

  buttonClicked(event: Event) {
    this.clickFn.emit(event);
  }

  private validateInputs() {
    if (this.href && this.forwardedRouterLink) {
      throw new Error('Cannot have both href and routerLink');
    }
  }

  private setSteps() {
    this.steps =
      this.size === 'large'
        ? ['text-base', 'text-sm', 'text-xs']
        : ['text-sm', 'text-xs'];
  }

  private setFullWidth() {
    const fullWidthClass = 'w-full';
    if (this.fullWidth) {
      this.class += ` ${fullWidthClass}`;
    } else {
      this.class = this.class.replace(` ${fullWidthClass}`, '');
    }
  }
}
